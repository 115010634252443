<template>
  <!-- <header class="header" :class="{ 'about-page': isAboutPage }" ref="thisHeader"> -->
  <header class="header" :class="{ 'about-header': isAboutPage }">
    <router-link to="/" class="logo"><img src="@/assets/img/logo.svg" alt=""></router-link>
    <ul id="menu">
      <li class="li-logo"><router-link to="/" class="logo"><img src="@/assets/img/logo.svg" alt=""></router-link></li>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <!-- <li><router-link to="/services">Services</router-link></li> -->
    </ul>
  </header>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
</template>

<script>
// import '@/assets/sass/3-modules/header.sass'
export default {
  name: 'Header',
  mounted() {
    // Access the element using the ref
    const thisHeader = this.$refs.thisHeader;

    // Add a class to the element
    // thisHeader.classList.add('header-loaded');
  },
  computed: {
    isAboutPage() {
      return this.$route.path === '/about';
    }
  }
}
</script>