<template>
  <!-- <div id="section10" class="end-section section section-nav" aTarget="navContact">
    <div class="contact-us">
      <div class="contact-center">
        <a id="scrollTop" href="javascript:void(0)"><img src="@/assets/img/caret-a.svg" alt="" loading="eager"></a>
        <h2>Let’s talk<span class="text-red">.</span></h2>
      </div>
      <footer>
        <div class="row">
          <div class="col-3 col-xs-12">
            <div class="footer-card"><img class="footer-logo" src="@/assets/img/logo.svg" alt="" loading="eager"></div>
          </div>
          <div class="col-3 col-xs-4">
            <div class="footer-card">
              <p>contact us</p>
              <a class="links" href="tel:(02)7914 2259">(02)7914 2259</a>
              <a class="links" href="tel:+63 917 591 8809">+63 917 591 8809</a>
              <a class="links" href="tel:+63 923 883 3215">+63 923 883 3215</a>
              <a class="links" href="mailto:hi@eve.com.ph">hi@eve.com.ph</a>
            </div>
          </div>
          <div class="col-3 col-xs-4">
            <div class="footer-card">
              <p>visit us</p>
              <span>28F, Menarco Tower, 32nd St., BGC, Taguig, Ph</span>
            </div>
          </div>
          <div class="col-3 col-follow-us">
            <div class="footer-card text-center">
              <p>follow us</p>
              <a href="https://www.facebook.com/eve.architects/" target="_blank"><img
                  preload-src="@/assets/img/logo-fb.svg" alt="" loading="eager"></a>
              <a href="https://www.instagram.com/eve.architects/?igshid=1nmsj1go91yui" target="_blank"><img
                  preload-src="@/assets/img/logo-insta.svg" alt="" loading="lazy"></a>
            </div>
          </div>
        </div>
        <p class="copy-right">Copyright 2024 Eve Designs. All Rights Reserved.</p>
      </footer>
    </div>
  </div> -->
  <footer><p class="copy-right">Copyright 2024 Eve Designs. All Rights Reserved.</p></footer>
</template>

<script>
// import '@/assets/sass/3-modules/Footer.sass'
export default {
  name: 'Footer',
  // mounted() {
  //   // Access the element using the ref
  //   const thisHeader = this.$refs.thisHeader;

  //   // Add a class to the element
  //   thisHeader.classList.add('header-loaded');
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
