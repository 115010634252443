<template>
  <transition name="fade">
    <div v-if="isLoading" class="preloader"></div>
  </transition>
  <Header></Header>
  <router-view/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    // Initialize your app
    this.initializeApp();
  },
  methods: {
    async initializeApp() {
      // Simulate loading delay or perform actual initialization logic
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Once initialization is complete, set loading to false
      this.isLoading = false;
    },
  },
}
</script>