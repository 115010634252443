<template>
  <div id="section1" class="section active section-nav">
    <!-- <transition name="fade">
      <div v-if="isLoading" class="preloader"></div>
    </transition> -->
    
    <div class="grid-container">
      <!-- 1 Gentle Monster -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Gentle Monster<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>IICombined Philippines, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>450sqm; Shangri-la at the Fort,  BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Construction Management Services</span>
                  <span>Post Construction Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/1.1.webp" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Gentle Monster<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>IICombined Philippines, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>450sqm; Shangri-la at the Fort,  BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Construction Management Services</span>
                  <span>Post Construction Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/1.2.webp" alt="">
      </div>

      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Gentle Monster<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>IICombined Philippines, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>450sqm; Shangri-la at the Fort,  BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Construction Management Services</span>
                  <span>Post Construction Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/1.3.webp" alt="">
      </div>

      <!-- 2 arabica mitsu -->
      <div onclick="" class="grid-item grid-53">
        <div>
          <div>
            <h1>Arabica Mitsukoshi<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Khoffee Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>140sqm Mitsukoshi Mall</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Post Construction Management</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/2.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-47">
        <div>
          <div>
            <h1>Arabica Mitsukoshi<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Khoffee Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>140sqm Mitsukoshi Mall</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Post Construction Management</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/2.2.jpg" alt="">
      </div>

      <!-- 3 arabica shang -->
      <div onclick="" class="grid-item grid-47">
        <div>
          <div>
            <h1>Arabica Shangri-la at the Fort<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Khoffee Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>200sqm Shangri-la at the Fort</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Post Construction Management</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/3.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-53">
        <div>
          <div>
            <h1>Arabica Shangri-la at the Fort<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Khoffee Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>200sqm Shangri-la at the Fort</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Post Construction Management</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/3.2.jpg" alt="">
      </div>

      <!-- 4 hungry whales dasma -->
      <div onclick="" class="grid-item grid-52 m-grid-100">
        <div>
          <div>
            <h1>Hungry Whale - SM Dasmariñas<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Fear the Deer</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; SM Dasmariñas</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/4.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-24 m-grid-33 s-grid-50">
        <div>
          <div>
            <h1>Hungry Whale - SM Dasmariñas<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Fear the Deer</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; SM Dasmariñas</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/4.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-24 m-grid-33 s-grid-50">
        <div>
          <div>
            <h1>Hungry Whale - SM Dasmariñas<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Fear the Deer</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; SM Dasmariñas</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/4.3.jpg" alt="">
      </div>

      <!-- 5 hungry north -->
      <div onclick="" class="grid-item grid-30 m-grid-33 s-grid-100">
        <div>
          <div>
            <h1>Hungry Whale - SM North<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Fear the Deer</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; SM Dasmariñas</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/5.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-70 m-grid-100">
        <div>
          <div>
            <h1>Hungry Whale - SM North<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Fear the Deer</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; SM Dasmariñas</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/5.2.jpg" alt="">
      </div>

      <!-- 6 art plus -->
      <div class="grid-inner grid-54">
        <div onclick="" class="grid-item grid-100">
          <div>
            <div>
              <h1>ArtPlus<span class="text-red">.</span></h1>
              <table>
                <tr>
                  <td>
                    <p>Client</p>
                  </td>
                  <td><span>ArtPlus Concepts Inc.</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Size and Location</p>
                  </td>
                  <td><span>100sqm Shangri-la at the Fort</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Typology and Scope</p>
                  </td>
                  <td>
                    <span>Art Gallery</span>
                    <span>Full Architectural Design</span>
                    <span>Construction Management Services</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <img src="@/assets/img/landing/6.1.jpg" alt="">
        </div>

        <div onclick="" class="grid-item grid-100">
          <div>
            <div>
              <h1>ArtPlus<span class="text-red">.</span></h1>
              <table>
                <tr>
                  <td>
                    <p>Client</p>
                  </td>
                  <td><span>ArtPlus Concepts Inc.</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Size and Location</p>
                  </td>
                  <td><span>100sqm Shangri-la at the Fort</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Typology and Scope</p>
                  </td>
                  <td>
                    <span>Art Gallery</span>
                    <span>Full Architectural Design</span>
                    <span>Construction Management Services</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <img src="@/assets/img/landing/6.2.jpg" alt="">
        </div>
      </div>

      <div onclick="" class="grid-item grid-46">
        <div>
            <div>
              <h1>ArtPlus<span class="text-red">.</span></h1>
              <table>
                <tr>
                  <td>
                    <p>Client</p>
                  </td>
                  <td><span>ArtPlus Concepts Inc.</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Size and Location</p>
                  </td>
                  <td><span>100sqm Shangri-la at the Fort</span></td>
                </tr>
                <tr>
                  <td>
                    <p>Typology and Scope</p>
                  </td>
                  <td>
                    <span>Art Gallery</span>
                    <span>Full Architectural Design</span>
                    <span>Construction Management Services</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        <img src="@/assets/img/landing/6.3.jpg" alt="">
      </div>

      <!-- 7 SOMA -->
      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>SOMA<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>80sqm; South of Market, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential </span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/7.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>SOMA<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>80sqm; South of Market, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential </span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/7.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-100">
        <div>
          <div>
            <h1>SOMA<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>80sqm; South of Market, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential </span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/7.3.jpg" alt="">
      </div>

      <!-- 8 Life style -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Lifestyle Area<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>The Kho Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>80sqm; Menarco Tower, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/8.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Lifestyle Area<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>The Kho Group</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>80sqm; Menarco Tower, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/8.2.jpg" alt="">
      </div>

      <!-- 9 Learning Lane -->
      <div onclick="" class="grid-item grid-35">
        <div>
          <div>
            <h1>Learning Lane<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Alphabet, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>180sqm; Menarco Tower, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Education </span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/9.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-65">
        <div>
          <div>
            <h1>Learning Lane<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Alphabet, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>180sqm; Menarco Tower, BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Education </span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/9.2.jpg" alt="">
      </div>

      <!-- 10 44b -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>44B<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>100sqm BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/10.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>44B<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>100sqm BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/10.2.jpg" alt="">
      </div>

      <!-- 11 pretty huge -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Pretty Huge<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Pretty Huge, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>2000sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Gym</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/11.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Pretty Huge<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Pretty Huge, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>2000sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Gym</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/11.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Pretty Huge<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Pretty Huge, Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>2000sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Gym</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/11.3.jpg" alt="">
      </div>

      <!-- 12 Line -->
      <div onclick="" class="grid-item grid-33 s-grid-100">
        <div>
          <div>
            <h1>LINE-CHAIR<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/12.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 s-grid-50">
        <div>
          <div>
            <h1>LINE-ISLAND<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/12.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 s-grid-50">
        <div>
          <div>
            <h1>LINE-WORKSTATION<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/12.3.jpg" alt="">
      </div>

      <!-- 13 Isla -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Isla<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/13.1.jpg" alt="">
      </div>

      <!-- 14 Rizal -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Rizal Townhouse<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr> 
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualization</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/14.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Rizal Townhouse<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr> 
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualization</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/14.2.jpg" alt="">
      </div>

      <!-- 15 ruby jane -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>RJR<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted; Bataan</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/15.1.jpg" alt="">
      </div>

      <!-- 16 -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Redacted<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/16.1.jpg" alt="">
      </div>

      <!-- 17 garden -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Garden House<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Visualization</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/17.1.jpg" alt="">
      </div>

      <!-- 18 Agave Brickroad  -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Agave Brickroad<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Altamira Group Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>200sqm Sta. Lucia Mall</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/18.1.jpg" alt="">
      </div>

      <!-- 19 Agave Eastwood -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Agave Eastwood<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Altamira Group Inc.</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>115sqm Eastwood Mall</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/19.1.jpg" alt="">
      </div>

      <!-- 20 PPTPP -->
      <div onclick="" class="grid-item grid-33 m-grid-100">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.3.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-100">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.4.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.5.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 m-grid-50">
        <div>
          <div>
            <h1>PPTPP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>700sqm; BGC</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/20.6.jpg" alt="">
      </div>

      <!-- 21 ATX  -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Atxs<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/21.1.jpg" alt="">
      </div>

      <!-- 22 PRHCB -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>PRHCB<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>1000sqm Roman Highway Bataan</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Site Development</span>
                  <span>Full Architecture Design Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/22.1.jpg" alt="">
      </div>

      <!-- 23 Foyer -->
      <div onclick="" class="grid-item grid-33 s-grid-100">
        <div>
          <div>
            <h1>Foyer<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/23.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 s-grid-50">
        <div>
          <div>
            <h1>Foyer<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/23.2.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-33 s-grid-50">
        <div>
          <div>
            <h1>Foyer<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>For Competition</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>N/A</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Competition</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/23.3.jpg" alt="">
      </div>

      <!-- 24 Borja -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Borja<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/24.1.jpg" alt="">
      </div>

      <!-- 25  -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Redacted<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr> 
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Investment Deck</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/25.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Redacted<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Investment Deck</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/25.2.jpg" alt="">
      </div>

      <!-- 26 Candelaria -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Candelaria<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; Rizal</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential Renovation</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/26.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>Candelaria<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>50sqm; Rizal</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential Renovation</span>
                  <span>Full Architectural Design</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/26.2.jpg" alt="">
      </div>

      <!-- 27 eastland -->
      <div onclick="" class="grid-item grid-73 s-grid-100">
        <div>
          <div>
            <h1>Eastland<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>200sqm Eastland Heights</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential Renovation</span>
                  <span>Full Architectural Design Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/27.1.jpg" alt="">
      </div>

      <!-- 28 l3d -->
      <div onclick="" class="grid-item grid-27 s-grid-100">
        <div>
          <div>
            <h1>L3D<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Retail</span>
                  <span>Full Architectural Services</span>
                  <span>Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/28.1.jpg" alt="">
      </div>

      <!-- 29  -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Redacted<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>F&B</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation for Investment Deck</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/29.1.jpg" alt="">
      </div>

      <!-- 30 VIP  -->
      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>VIP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/30.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-50">
        <div>
          <div>
            <h1>VIP<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Full Architectural Services</span>
                  <span>Construction</span>
                  <span>Post Construction Management Services</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/30.2.jpg" alt="">
      </div>

      <!-- 31 usman  -->
      <div onclick="" class="grid-item grid-100">
        <div>
          <div>
            <h1>Usman<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted; Bataan</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/31.1.jpg" alt="">
      </div>

      <!-- 32 katarungan  -->
      <div onclick="" class="grid-item grid-47">
        <div>
          <div>
            <h1>Katarungan House Extension<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/32.1.jpg" alt="">
      </div>

      <!-- 33  -->
      <div onclick="" class="grid-item grid-53">
        <div>
          <div>
            <h1>Redacted<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Office</span>
                  <span>Architectural Concept Design</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/33.1.jpg" alt="">
      </div>

      <!-- 34 Usisa  -->
      <div onclick="" class="grid-item grid-53">
        <div>
          <div>
            <h1>U-N<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted; Laguna</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/34.1.jpg" alt="">
      </div>

      <div onclick="" class="grid-item grid-47">
        <div>
          <div>
            <h1>U-N<span class="text-red">.</span></h1>
            <table>
              <tr>
                <td>
                  <p>Client</p>
                </td>
                <td><span>Redacted</span></td>
              </tr>
              <tr>
                <td>
                  <p>Size and Location</p>
                </td>
                <td><span>Redacted; Laguna</span></td>
              </tr>
              <tr>
                <td>
                  <p>Typology and Scope</p>
                </td>
                <td>
                  <span>Residential</span>
                  <span>Full Architectural Services</span>
                  <span>Visualisation</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <img src="@/assets/img/landing/34.2.jpg" alt="">
      </div>

      <!-- end of grid -->
    </div>
    <!-- Footer -->
    <button class="btn-scroll-top" :class="{ 'show': isShow }" @click="scrollToTop"><img src="@/assets/img/icon-up.svg"
        alt=""></button>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import '@/assets/sass/4-pages/landing.sass'
import { ref, onMounted } from 'vue';
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Footer
  },
  data() {
    return {
      // isLoading: true,
      isShow: false
    };
  },
  mounted() {
    // // Access the element using the ref
    // const thisSection = this.$refs.thisSection;
    // // const isLoaded = false;
    // console.log(this.isLoaded);

    window.addEventListener('scroll', this.handleScroll);

    // Add a class to the element
    // thisSection.classList.add('loaded');

    // setTimeout(() => {
    //   this.isLoaded = true;
    //   console.log(this.isLoaded);
    //   // thisSection.classList.add('loaded');
    // }, 250);
  //   const images = document.querySelectorAll('img');
  //   let loadedCount = 0;
  //   const totalImages = images.length;

  //   // Handle the case where there are no images
  // if (totalImages === 0) {
  //   this.isLoading = false;
  //   return;
  // }

  // const imageLoaded = () => {
  //   loadedCount++;
  //   if (loadedCount >= totalImages) {
  //     this.isLoading = false;
  //   }
  // };

  // images.forEach((img) => {
  //   if (img.complete) {
  //     // Image is already loaded from cache
  //     imageLoaded();
  //   } else {
  //     img.addEventListener('load', imageLoaded);
  //     img.addEventListener('error', imageLoaded);
  //   }
  // });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isShow = window.scrollY !== 0;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
